import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Button from "../components/button"
import H1 from "../components/h1"
import Layout from "../components/layout"
import Narrow from "../components/narrow"
import { useStaticQuery, graphql } from "gatsby"

const StyledNoFoundPage = styled.div`
  text-align: center;
`

const NotFoundPage = () => {
  const query = useStaticQuery(graphql`
    query errorQuery {
      image: file(relativePath: { eq: "tech-2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout headerImage={query.image.childImageSharp.fluid} helmet="404">
      <StyledNoFoundPage>
        <Narrow>
          <H1>Sidan hittades inte</H1>
          <Link to="/">
            <Button>Tillbaka till hem</Button>
          </Link>
        </Narrow>
      </StyledNoFoundPage>
    </Layout>
  )
}

export default NotFoundPage
